import styled from 'styled-components'

import media from './MediaQueries'

const InnerStyled = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.innerWidth};
  margin: 0 auto;
  padding-right: ${({ theme }) => theme.gutter.gutter16};
  padding-left: ${({ theme }) => theme.gutter.gutter16};

  @media (${media.phone}) {
    padding-right: ${({ theme }) => theme.gutter.gutter32};
    padding-left: ${({ theme }) => theme.gutter.gutter32};
  }
`

const MsgStyled = styled.p`
  padding: ${({ theme }) => theme.gutter.gutter16};
  text-align: center;
  font-style: italic;
`

export {
  InnerStyled,
  MsgStyled,
}
