import { createGlobalStyle } from 'styled-components'
import reset from '../common/reset'

export default createGlobalStyle`
  ${reset};

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
  }

  body {
    font-family: 'Maax', sans-serif;
    font-size: 16px;
    line-height: 1.25;
    color: #011556;
  }
`
